
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class PondMonitorList extends Vue {
  private tableData: Array<{ projectName: string; totalAmount: string; onlineAmount: string; offlineAmount: string }> = []
  private searchInfo: { projectName: string } = {
    projectName: ''
  }

  private page = 1
  private size = 10
  private total = 0
  private loading = false

  created() {
    this.getData()
  }

  getData() {
    this.loading = true
    this.$axios.get(this.$apis.mistForest.selectSumProjectList, {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
      console.log(res, '"res"')
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch() {
    this.page = 1
    this.getData()
  }

  onDetail(id: string) {
    this.$router.push({
      name: 'mistForestDetail',
      query: { projectId: id }
    })
  }
}
